_.mixin({
  isNullOrUndefined: function (v) {
    return _.isNull(v) || _.isUndefined(v);
  },

  isEmail: function (v) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v);
  },
});

// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    }
  });
}

window.DATATABLE_FOOTER_CALLBACK_GROUPS_DATA = [];

window.CURRENT_DATATABLE = [];

function STARTSWITH(haystack, needle) {
  return haystack.lastIndexOf(needle, 0) === 0;
}

function QS_GET(name) {
  return url(name);
}

function QS_GET_STARTWITH(start) {
  var object = url('?');
  var data = [];

  for (var p in object) {
    if (object.hasOwnProperty(p) && STARTSWITH(p, start)) {
      data.push([p, object[p]]);
    }
  }

  return data;
}

function NAVIGATIONBAR_TOGGLE() {
  var colapse = false;

  $('#nav-menusafekat').toggleClass('colapsed-menu');
  $('#wrapper').toggleClass('colapsed-wrapper');

  if ($('#nav-menusafekat').hasClass('colapsed-menu')) {
    colapse = false;
    $('i', this).addClass('fa-angle-right').removeClass('fa-angle-left');
    $('a[data-toggle="collapse"]', $('#nav-menusafekat')).addClass('colapsed-link');
  } else {
    colapse = true;
    $('i', this).addClass('fa-angle-left').removeClass('fa-angle-right');
    $('a[data-toggle="collapse"]', $('#nav-menusafekat')).removeClass('colapsed-link');
  }

  $('li.panel', $('#nav-menusafekat')).each(function (i, item) {
    $('span', item).toggleClass('hidden');
    if ($('ul', item).hasClass('in')) {
      $('ul', item).removeClass('in');
    }
  });

  $.event.trigger({
    type: "navigationbar-toggle",
    colapse: colapse,
    time: new Date()
  });
}

function FORMAT_INT(v) {
  return parseInt(v, 10);
}

function FORMAT_CURRENCY(d, precision, simbolo) {
  var thousands = '.';
  var decimal = ',';
  var precision = !_.isUndefined(precision) ? precision : 2;

  if (typeof d !== 'number' && typeof d !== 'string') {
    return d;
  }

  var negative = d < 0 ? '-' : '';
  d = parseFloat(Math.abs(parseFloat(d)).toFixed(precision));

  var intPart = parseInt(d, 10);
  var floatPart = decimal + (d - intPart).toFixed(precision).substring(2);

  var simbolo_render = '';
  if (simbolo)
    simbolo_render = ' ' + simbolo;

  return negative +
    intPart.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g, thousands
    ) +
    floatPart + simbolo_render;
}

function DATATABLE_COLUMNS_SEARCH_QUERY(idTable, table) {
  var search = [];

  $(idTable + ' thead tr.filter th').each(function () {
    if ($(this).hasClass('search')) {
      var title = $(this).text(),
        index = $(this).index();

      column = table.columns(index),
        current_search = column.search()[0];

      if (current_search) {
        search.push({
          'index': index,
          'search': current_search
        });
      }
    }
  });

  return search;
}

function DATATABLE_COLUMNS_SEARCH(idTable, TABLE) {
  // filters in url, se guardan los valores recibido en columnInitValues
  var params = QS_GET_STARTWITH("dtfilter__");
  var columnInitValues = {};

  if (params.length > 0) {
    for (var i = 0; i < params.length; i++) {
      var column = params[i][0].split('__')[1],
        value = params[i][1],
        columnsSrc = TABLE.columns().dataSrc(),
        index = null;

      for (var j = 0; j < columnsSrc.length; j++) {
        if (column === columnsSrc[j]) {
          index = j;
          break;
        }
      }
      if (index) {
        columnInitValues[index] = value;
      }
    }
  }

  // procesamos inputs de la cabecera
  $(idTable + ' thead tr.filter th').each(function () {
    if ($(this).hasClass('search')) {
      var title = $(this).text(),
        index = $(this).index(),
        value = columnInitValues[index] ? columnInitValues[index] : '';

      if ($(this).hasClass('input-date') || $(this).hasClass('input-date-range')) {
        var self = this,
          options = $(this).data('date-range-options'),
          data = {
            // singleDatePicker: true,
            // startDate: "02/29/2016",
            // endDate: "02/29/2016",
            ranges: {
              'Hoy': [moment(), moment()],
              'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Este mes': [moment().startOf('month'), moment().endOf('month')],
              'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            autoUpdateInput: false,
            locale: {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpiar',
              'applyLabel': 'Seleccionar',
              'fromLabel': 'Desde',
              'toLabel': 'Hasta',
              'daysOfWeek': [
                'Do',
                'Lu',
                'Ma',
                'Mi',
                'Ju',
                'Vi',
                'Sa'
              ],
              'monthNames': [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
              ],
              'firstDay': 1
            },
          };

        $.extend(data, options);

        $(self).html(
          '<div class="form-group">' +
          '<input type="text" class="form-control datatable-input-search" data-index="' + index + '" value="' + value + '" readOnly="readOnly"/>' +
          '</div>'
        );

        $('input', self).daterangepicker(data);
        $('input', self).on('apply.daterangepicker', function (ev, picker) {
          var start = picker.startDate.format('DD/MM/YY'),
            end = picker.endDate.format('DD/MM/YY'),
            text = null;

          if (start == end) {
            text = start;
            $(this).val(text);
            $(this).removeClass('range');
          } else {
            text = start + ' - ' + end;
            $(this).val(text);
            $(this).addClass('range');
          }

          var index = $(this).data('index'),
            column = TABLE.columns(index),
            current_search = column.search()[0];

          if (current_search !== text) {
            column.search(text).draw();
          }
        });
        $('input', self).on('cancel.daterangepicker', function (ev, picker) {
          $(this).val('');
          $(this).removeClass('range');

          var index = $(this).data('index'),
            column = TABLE.columns(index),
            current_search = column.search()[0];

          if (current_search !== '') {
            column.search('').draw();
          }
        });

      } else if ($(this).hasClass('input-select')) {

        var items = $(this).data('items'),
          items_html = '';

        if (items) {
          //items = $.parseJSON(items);
          for (var i = 0; i < items.length; i++) {
            var selected = value == items[i][0];
            items_html += '<option value="' + items[i][0] + '" ' + (selected ? 'selected' : '') + '>' + items[i][1] + '</option>';
          }
        }

        $(this).html(
          '<div class="form-group">' +
          '<select class="form-control datatable-input-search" data-index="' + index + '">' +
          '<option value="0">--</option>' +
          items_html +
          '</select>' +
          '</div>'
        );

      } else if ($(this).hasClass('input-select-multi')) {
        var items = $(this).data('items'),
          items_html = '';

        if (items) {
          //items = $.parseJSON(items);
          for (var i = 0; i < items.length; i++) {
            var selected = value == items[i][0];
            items_html += '<option value="' + items[i][0] + '" ' + (selected ? 'selected' : '') + '>' + items[i][1] + '</option>';
          }
        }

        $(this).html(
          '<div class="form-group">' +
          '<select class="form-control datatable-input-search" data-index="' + index + '" multiple="multiple">' +
          items_html +
          '</select>' +
          '</div>'
        );

        $('select[data-index="' + index + '"]').select2({
          tags: true,
          tokenSeparators: [',', ' '],
          containerCssClass: 's2-multi',
          dropdownCssClass: 's2-multi-drop'
        });

      } else {
        $(this).html(
          '<div class="form-group">' +
          '<input type="text" class="form-control datatable-input-search" data-index="' + index + '" value="' + value + '"/>' +
          '</div>'
        );
      }
    }
  });

  // activamos los callbacks search
  TABLE.columns().every(function (indexColumn) {
    var self = this;
    var th = $(idTable + ' thead tr.filter th:eq(' + indexColumn + ')');

    $(':input', th).on('change', function () {
      var index = $(this).data('index');
      var column = TABLE.columns(index);
      var current_search = column.search()[0];
      var value = this.value;

      if ($(this).attr('multiple') == 'multiple') {
        value = JSON.stringify($(this).val());
      }

      if (current_search !== value) {
        column.search(value).draw();
      }
    });

    if (columnInitValues[indexColumn]) {
      var column = TABLE.columns(indexColumn);
      var current_search = column.search()[0];
      var inputValue = $(idTable + ' thead tr.filter th:eq(' + indexColumn + ') :input').val();

      if (current_search !== inputValue) {
        column.search(inputValue).draw();
      }
    }
  });

  DATATABLE_UPDATE_WIDTH();
}

function DATATABLE_FOOTER_CALLBACK(idTable, table) {
  var api = table.api();
  var columnsLength = table.fnSettings().aoColumns.length;
  var tableGroups = window.DATATABLE_FOOTER_CALLBACK_GROUPS_DATA[idTable];

  for (var i = 0; i < columnsLength; i++) {
    $('tfoot tr.group th', table).each(function () {
      var index = $(this).index();
      if ($(this).hasClass('sum')) {
        var sumPage, sumTotal, text;

        // total de la pagina
        sumPage = api.column(index, {
          page: 'current'
        }).data().reduce(function (a, b) {
          return a + b;
        }, 0);
        text = FORMAT_CURRENCY(sumPage);

        // total global
        if (tableGroups) {
          sumTotal = tableGroups[index];
          textTotal = FORMAT_CURRENCY(sumTotal)
          if (sumTotal && text != textTotal) {
            text += " (" + textTotal + ")";
          }
        }

        $(api.column(index).footer()).html(text);
        if ($(table).find('tr.filter')) {
          $(table).find('tr.filter th:eq(' + index + ')').html(text);
        }
      }
    });
  }
}

function DATATABLE_RENDER(idTable, config) {
  var getHeaderFilter = function (table) {
    return $('tr.filter', table.header());
  };

  /**
   * Actualiza las cabecera añadiendo (si hay) las sumatorias del pie
   */
  var fntUpdateHeaders = function (api) {
    api.columns().every(function () {
      var column = this;
      var index = column.index();
      var table = column.table();
      var headerFilter = getHeaderFilter(table);
      var footer = table.column(index).footer();

      if ($(footer).hasClass('sum') || $(footer).hasClass('avg-weight')) {
        var column = $(footer).data('column');
        var columnNode = $('td[data-column="' + column + '"]', headerFilter);
        if (columnNode) {
          $(columnNode).html($(footer).html());
        }
      }
    });
  };

  /**
   * Cuando termina de inicializar la tabla realiza acciones:
   *  - Añadir campos de búsqueda si están configurados
   *      Inputs: input-date, input-date-range, input-select,
   *              input-select-multi, input-text
   *  - Comprobar si hay filtros para las búsquedas en la url
   */
  var fntInitComplete = function (settings, json) {
    //console.log("fntInitComplete!!!!!!!!!!!!!!");

    // comprobamos si hay search o sumatorias
    this.api().columns().every(function () {
      var column = this;
      var index = column.index();
      var table = column.table();
      var footer = table.column(index).footer();
      var setting = table.settings()[0].aoColumns[index];

      if (setting.creanic_search || $(footer).hasClass('sum')) {
        hasSearch = true;
      }
    });

    if (!hasSearch) {
      return;
    }

    // creamos un fila en el header para los filtros
    var header = this.api().table().header();
    var headerFilter = $('<tr class="filter"></tr>');
    $('th', header).each(function (i, th) {
      var footer = table.column(i).footer();
      var column = $(footer).data('column');
      column = column ? column : '';
      var td = $('<td data-column="' + column + '"></td>')
        .attr('class', $(footer).attr('class'));
      headerFilter.append(td);
    });
    $(header).append(headerFilter);
    // headerFilter = getHeaderFilter(this.api().table());

    // comprobamos si hay filters en la url,
    // se guardan los valores recibido en columnInitValues
    var params = QS_GET_STARTWITH("dtfilter__");
    var hasSearch = false;
    var columnInitValues = {};

    if (params.length > 0) {
      for (var i = 0; i < params.length; i++) {
        var column = params[i][0].split('__')[1],
          value = params[i][1],
          columnsSrc = this.table().columns().dataSrc(),
          index = null;

        for (var j = 0; j < columnsSrc.length; j++) {
          if (column === columnsSrc[j]) {
            index = j;
            break;
          }
        }
        if (index) {
          columnInitValues[index] = value;
        }
      }
    }

    // procesamos columnas, añadiendo inputs de busqueda
    this.api().columns().every(function (indexColumn) {
      var column = this;
      var index = column.index();
      var table = column.table();
      var header = $('td:nth-child(' + (index + 1) + ')', headerFilter);
      var setting = table.settings()[0].aoColumns[index];
      var value = columnInitValues[index] ? columnInitValues[index] : '';
      var inputEvent = null;

      if (!setting.creanic_search) {
        $('<div style="height: 30px;">&nbsp;</div>').appendTo(header);
      } else {
        var type = setting.creanic_search.type;
        var options = setting.creanic_search.options;
        var initial = setting.creanic_search.initial;

        if (type == 'input-date' || type == 'input-date-range') {

          var data = {
            ranges: {
              'Hoy': [moment(), moment()],
              'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Este mes': [moment().startOf('month'), moment().endOf('month')],
              'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            autoUpdateInput: false,
            locale: {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpiar',
              'applyLabel': 'Seleccionar',
              'fromLabel': 'Desde',
              'toLabel': 'Hasta',
              'daysOfWeek': [
                'Do',
                'Lu',
                'Ma',
                'Mi',
                'Ju',
                'Vi',
                'Sa'
              ],
              'monthNames': [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
              ],
              'firstDay': 1
            },
          };

          $.extend(data, options);

          $(
            '<div class="form-group table-search">' +
            '<input type="text" class="form-control datatable-input-search" ' +
            'data-index="' + index + '" value="' + value + '" readOnly="readOnly"/>' +
            '</div>'
          ).appendTo(header);

          $('input', header).daterangepicker(data);
          $('input', header).on('apply.daterangepicker', function (ev, picker) {
            var start = picker.startDate.format('DD/MM/YY');
            var end = picker.endDate.format('DD/MM/YY');
            var text = null;

            if (start == end) {
              text = start;
              $(this).val(text);
              $(this).removeClass('range');
            } else {
              text = start + ' - ' + end;
              $(this).val(text);
              $(this).addClass('range');
            }

            var current_search = column.search()[0];

            if (current_search !== text) {
              column.search(text).draw();
            }
          });
          $('input', header).on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
            $(this).removeClass('range');

            var current_search = column.search()[0];

            if (current_search !== '') {
              column.search('').draw();
            }
          });

        } else if (type == 'input-select') {

          var items = setting.creanic_search.items;
          var items_html = '';

          if (items) {
            //items = $.parseJSON(items);
            for (var i = 0; i < items.length; i++) {
              var selected = value == items[i][0];

              if (!selected && initial) {
                if (initial === items[i][0]) {
                  selected = true;
                }
              }

              items_html += '<option value="' + items[i][0] + '" ' + (selected ? 'selected' : '') + '>' + items[i][1] + '</option>';
            }
          }

          $(
            '<div class="form-group table-search">' +
            '<select class="form-control datatable-input-search" data-index="' + index + '">' +
            '<option value="0">--</option>' +
            items_html +
            '</select>' +
            '</div>'
          ).appendTo(header);

          inputEvent = $('select', header);

        } else if (type == 'input-select-multi') {

          var items = setting.creanic_search.items;
          var items_html = '';

          if (items) {
            //items = $.parseJSON(items);
            for (var i = 0; i < items.length; i++) {
              var selected = null;

              if (initial) {
                for (var j = 0; j < initial.length; j++) {
                  selected = items[i][0] == initial[j];
                }
              }
              items_html += '<option value="' + items[i][0] + '" ' + (selected ? 'selected' : '') + '>' + items[i][1] + '</option>';
            }
          }

          $(
            '<div class="form-group table-search">' +
            '<select class="form-control datatable-input-search" data-index="' + index + '" multiple="multiple">' +
            items_html +
            '</select>' +
            '</div>'
          ).appendTo(header);

          $('select[data-index="' + index + '"]').select2({
            tags: true,
            tokenSeparators: [',', ' '],
            containerCssClass: 's2-multi',
            dropdownCssClass: 's2-multi-drop'
          });

          inputEvent = $('select[data-index="' + index + '"]', header);

        } else if (type == 'input-text') {
          $(
            '<div class="form-group table-search">' +
            '<input type="text" class="form-control datatable-input-search" data-index="' + index + '" value="' + value + '"/>' +
            '</div>'
          ).appendTo(header);

          inputEvent = $('input', header);

        } else if (type == 'custom') {
          var html = setting.creanic_search.render(this);
          $(html).appendTo(header);
        }

        // aplicamos eventos
        if (inputEvent) {
          $(inputEvent).on('change', function (event) {
            var index = $(this).data('index');
            var current_search = column.search()[0];
            var value = this.value;

            if ($(this).attr('multiple') == 'multiple') {
              value = JSON.stringify($(this).val());
            }

            if (current_search !== value) {
              column.search(value).draw();
            }
          });
        }
      }
    });

    fntUpdateHeaders(this.api());

    // funcion del usuario si existe
    if (config.initComplete) {
      config.initComplete.bind(this)(settings, json);
    }
  };

  /**
   * Evento cada vez que se pinta la tabla
   *  - Actutaliza los totales de la cabecera si existen
   */
  var fntDrawCallback = function (settings) {
    fntUpdateHeaders(this.api());

    if (config.drawCallback) {
      config.drawCallback.bind(this)(settings);
    }
  };

  /**
   * Evento cuando se pinta el footer
   *  - Realiza sumatorias de las columnas (si están definidas)
   */
  var fntFooterCallback = function (tfoot, data, start, end, display) {
    var api = this.api();
    var settings = this.api().settings()[0];

    api.columns().every(function () {
      var column = this;
      var index = column.index();
      var table = column.table();
      var header = table.column(index).header();
      var footer = table.column(index).footer();
      var setting = table.settings()[0].aoColumns[index];
      var json = table.ajax.json();
      var decimal = parseInt($(footer).data('decimals'), 10);
      var simbol = $(footer).data('simbol');

      decimal = decimal ? decimal : 2;
      simbol = simbol ? simbol : '';

      if ($(footer).hasClass('sum')) {
        var sumPage, text;

        // total de la pagina
        sumPage = column.data().reduce(function (a, b) {
          if (!a) {
            return b;
          }
          if (!b) {
            return a;
          }
          return parseFloat(a, 10) + parseFloat(b, 10);
        }, 0);
        text = FORMAT_CURRENCY(sumPage, decimal);

        // total global
        if (json.groups_columns_response && json.groups_columns_response[index]) {
          var sumTotal = json.groups_columns_response[index];
          var textTotal = FORMAT_CURRENCY(sumTotal, decimal);
          if (sumTotal && text != textTotal) {
            text += " (" + textTotal + ")";
          }
        }

        text += simbol;

        $(footer).html(text);
      } else if ($(footer).hasClass('avg-weight')) {
        var col1Name = $(footer).data('column1');
        var col2Name = $(footer).data('column2');
        var data = settings.aoData;
        var sum1 = 0;
        var sum2 = 0;
        var text;

        for (var i = 0; i < data.length; i++) {
          var v1 = parseFloat(data[i]._aData[col1Name], 10);
          var v2 = parseFloat(data[i]._aData[col2Name], 10);

          if (v1) {
            sum1 += v1;
          }

          if (v2) {
            sum2 += v2;
          }
        }

        text = FORMAT_CURRENCY(sum1 / sum2, decimal)
        text += simbol;
        $(footer).html(text);
      }
    });

    fntUpdateHeaders(api);

    if (config.footerCallback) {
      config.footerCallback.bind(this)(tfoot, data, start, end, display);
    }
  };

  var configdt = $.extend({}, {
    "processing": true,
    "serverSide": true,
    "autoWidth": false,
    "responsive": {
      details: true
    },
    "order": [
      [1, 'desc']
    ],
    "pageLength": 100,
    "sDom": "lpfrtip",
    "language": {
      "sProcessing": "Procesando...",
      "sLengthMenu": "Mostrar _MENU_ registros",
      "sZeroRecords": "No se encontraron resultados",
      "sEmptyTable": "Ningún dato disponible en esta tabla",
      "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
      "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
      "sInfoFiltered": "",
      "sInfoPostFix": "",
      "sSearch": "Buscar:",
      "sUrl": "",
      "sInfoThousands": ",",
      "sLoadingRecords": "Cargando...",
      "oPaginate": {
        "sFirst": "Primero",
        "sLast": "Último",
        "sNext": "Siguiente",
        "sPrevious": "Anterior"
      },
      "oAria": {
        "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
      }
    }
  }, config);

  // comprobamos si la última tienen prioridad 1 (son las últimas en ocultarse)
  var columnsPriority = [configdt.columns[0], configdt.columns[configdt.columns.length - 1]];
  //var columnsPriority = [configdt.columns[configdt.columns.length-1]];
  for (var i = 0; i < columnsPriority.length; i++) {
    var c = columnsPriority[i];
    if (!c.responsivePriority) {
      c['responsivePriority'] = -1;
    }
  }

  // callback init
  configdt['initComplete'] = fntInitComplete;
  configdt['drawCallback'] = fntDrawCallback;
  configdt['footerCallback'] = fntFooterCallback;

  // constructor de la tabla
  var table = $(idTable).DataTable(configdt);
  window.CURRENT_DATATABLE.push(table);

  // eventos cuando se redimensiona la tabla
  table.on('responsive-resize', function (e, datatable, row, showHide, update) {
    var trFilter = $('thead > tr.filter', $(idTable));
    var settings = datatable.settings()[0];

    for (var i = 0; i < row.length; i++) {
      var cols = settings.aoColumns[i];

      if (cols.creanic_search) {
        var thFilter = $('td:nth-child(' + (i + 1) + ')', trFilter);

        if (row[i]) {
          $(thFilter).show();
        } else {
          $(thFilter).hide();
        }
      }
    }
  });

  return table;
}

function DATATABLE_UPDATE_WIDTH() {
  // for (table of window.CURRENT_DATATABLE) {
  //   console.log("update width");
  //   // table.columns.adjust()
  //   //      .responsive.recalc();
  //   // table.responsive.recalc();
  //   // table.responsive.rebuild();
  // }
}

function TOGGLE_BUTTON_UPDATE_POSITION() {
  var topSize = $(window).height() - 35;
  $('.btn-colapse-menu').css({
    position: 'fixed',
    top: topSize + "px"
  });
}

function UPDATE_MESSAGE_COUNT(url) {
  $.get(url, function (res) {
    var count = FORMAT_INT(res.count);
    var node = $('li.messages-count').find('.count');
    var nodeAlert = $('div.panel-menssages-alert');

    if (count > 0) {
      $(node).html('<span class="bubble">' + count + '</span>');
      $(nodeAlert).find('.huge').html(count);
      $(nodeAlert).removeClass('hide');
    } else {
      $(node).html('');
      $(nodeAlert).addClass('hide');
    }
  });
}

function error_bag_flatten(errors, glue) {
  // {"name":["The name field is required."],"password":["The password confirmation does not match."]}
  var texts = _.flatten(_.values(errors));
  var glue = _.isNullOrUndefined(glue) ? '<br>' : glue;

  return _.reduce(texts, function (memo, item) {
    return memo + glue + item;
  });
}

function error_ajax(response, title) {
  var msg = null;
  var title = title || "Error";

  if (_.has(response.responseJSON, 'errors')) {
    msg = error_bag_flatten(response.responseJSON.errors);
  } else {
    msg = response.statusText;
  }

  BootstrapDialog.show({
    type: BootstrapDialog.TYPE_DANGER,
    title: title,
    message: msg,
    buttons: [{
      label: 'Cerrar',
      action: function (dialogItself) {
        dialogItself.close();
      }
    }]
  });
}

function msg_alert(message, title) {
  var msg = message;
  var title = title || "Alert";

  BootstrapDialog.show({
    type: BootstrapDialog.TYPE_WARNING,
    title: title,
    message: msg,
    buttons: [{
      label: 'Cerrar',
      action: function (dialogItself) {
        dialogItself.close();
      }
    }]
  });
}

function msg_confirm(message, title, cb) {
  var msg = message;
  var title = title || "Confirm";

  BootstrapDialog.confirm({
    type: BootstrapDialog.TYPE_WARNING,
    title: title,
    message: msg,
    callback: cb
  });
}